// TextSlider.js
import React, { useState, useEffect } from 'react';

function TextSlider() {
  const [currentText, setCurrentText] = useState(0);
  const texts = ["DATA TO DOLLARS", "IT'S ALL ABOUT YOU", "UMEDX"];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentText((prevText) => (prevText + 1) % texts.length);
    }, 6000); // Change text every 8 seconds (adjust as needed)

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="text-slider">
      <div className="text-slider-content">
        {texts.map((text, index) => (
          <div
            key={index}
            className={`text-slider-item ${index === currentText ? 'active' : ''}`}
            style={{ animationDelay: `${index * 8}s` }} // Delay each item's animation
          >
            {text}
          </div>
        ))}
      </div>
    </div>
  );
}

export default TextSlider;
