// Navbar.js
import React from 'react';
import './Navbar.css'; // Import the Navbar CSS

function Navbar() {
  const handleSurveyClick = (event) => {
    event.preventDefault();
    window.location.href = "https://umedxsurvey.netlify.app/";
  };

  return (
    <div className="navbar">
      <a href="/" className="logo"><span className="UMEDX">U</span>MEDX</a>
      <div className="nav-links">
        {/* <a href="#about">About</a>
        <a href="#services">Services</a>
        <a href="#contact">Contact</a> */}
      </div>
      {/* Add the onClick handler to trigger manual redirection */}
      <a href="#" onClick={handleSurveyClick} className="survey-link">Survey</a>
    </div>
  );
}

export default Navbar;
