// import './App.css';
// import bgvideo from './launch2.mp4'
// // import bgphoto from './launch.png'




// function App() {

//   return (
// <div className='app'>
//       <div className='video-container'>
//         <video autoPlay loop muted playsInline src={bgvideo}>
//         </video>
//       </div>

//       <div className='text'>
//           <form
//             method="POST" 
//             //Working Example without Email automation
//             //action="https://script.google.com/macros/s/AKfycbxtts_aEngqhT2duaSKUG1l67dN9XYjq78wG6fCox9Xw3wXiz5YmUf3rCOtcUgG72VC/exec"
//             //TEST
//             action="https://script.google.com/macros/s/AKfycbx7v7mcstiHN5CUBBRAmvsAnnOujDtHmFk4EFQSMYjIJxOBPx87acfFCmDPrHuBZeo/exec"
//             //Automation test
//             // action='https://script.google.com/macros/s/AKfycbyWK2QGeOKMoP-h9C20NCMMx6sbvZ2JlGaWawdeHf_xryhYq2PAy3DzAj2SrU0stkTn/exec'
//             id="my-form"
//             class="gform"
//             >
//           <h3>Check out our recent <a href="https://online.fliphtml5.com/fitxg/ezqs/#p=1" target="_blank">publication</a></h3>
//           <p>Sign up below to be notified when we launch!</p>
//           <input name="FirstName" type="text" placeholder="First Name" required></input>
//           <input name="Email" type="email" placeholder="Email" required></input>
//           <button type="submit">Notify Me </button>
//           </form>
         



//       </div>



// </div>    
// )
// }

// export default App;



import React from 'react';
import './App.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles
import { Carousel } from 'react-responsive-carousel'; // Import Carousel component
import bg from './datadollars.png';
import s1 from './Slide1.png';
import s2 from './Slide2.png';
import s3 from './Slide3.png';
import s4 from './Slide4.png';
import TextSlider from './TextSlider'; 
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './Navbar'; // Import the Navbar component
import { useEffect } from 'react';

function SurveyRedirect() {
  useEffect(() => {
    // Redirect to external URL
    window.location.href = "https://www.umedxsurvey.netlify.app";
  }, []);

  return null; // The component doesn't render anything, it just redirects
}


function App() {
  return (
    <div className="app">
      <div className="background">
      <img src={bg} alt="Background" />
      </div>
     <Router>
      <Navbar />
      <Routes>
        {/* Define the /survey route that triggers the redirect */}
        <Route path="/survey" element={<SurveyRedirect />} />
      </Routes>
    </Router>
      <div className="content">
      {/* <div className="slider-container">
          <Carousel showThumbs={false} showStatus={false} infiniteLoop={true} autoPlay={true} interval={3000}>
            <div>
              <img src={s1} alt="Image 1" className="slider-image"/>
            </div>
            <div>
              <img src={s2}alt="Image 2"className="slider-image" />
            </div>
            <div>
              <img src={s3} alt="Image 3"className="slider-image" />
            </div>
            <div>
              <img src={s4} alt="Image 4" className="slider-image"/>
            </div>
            {/* Add more images as needed */}
          
        <TextSlider />
      <div className="form-container">
          <form
            method="POST" 
            //Working Example without Email automation
            //action="https://script.google.com/macros/s/AKfycbxtts_aEngqhT2duaSKUG1l67dN9XYjq78wG6fCox9Xw3wXiz5YmUf3rCOtcUgG72VC/exec"
            //TEST
            action="https://script.google.com/macros/s/AKfycbxqU2HVypZJsRJt3Gqmujvol_-haNYzxvriHoGpiFOZLkQwbb8TXY7DyE1vDhb1ksLf_w/execY"
            //Automation test
            // action='https://script.google.com/macros/s/AKfycbyWK2QGeOKMoP-h9C20NCMMx6sbvZ2JlGaWawdeHf_xryhYq2PAy3DzAj2SrU0stkTn/exec'
            id="my-form"
            // class="gform"
            >
          <h3>Interested in UMEDX? Learn more by taking our <a href="https://umedxsurvey.netlify.app" target="_blank"> data survey</a> today!</h3>
          {/* <h3>Check out our recent <a href="https://online.fliphtml5.com/fitxg/ezqs/#p=1" target="_blank"> publication</a></h3> */}
          <p>Sign up below for notifications</p>
          <input name="FirstName" type="text" placeholder="First Name" required></input>
          <input name="Email" type="email" placeholder="Email" required></input>
          <button type="submit">Notify Me </button>
          </form>
          </div>
          
      </div>
    </div>
  );
}

export default App;